export const baseUrl = "https://kjdlearnopenaift-claimschecker.azurewebsites.net";
//export const baseUrl = "http://localhost:5079";

export const authorizedFetch = (url, token, logout, options) => {
    options = options || {};
    if (token) {
        options.headers = {
            ...options.headers,
            Authorization: `Bearer ${token}`
        };
    }
    return fetch(url, options).then(response => {
        if (response.status === 401) {
            logout();
        }
        return response;
    });
}