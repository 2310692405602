import {Fragment, useMemo, useState} from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
    Typography
} from "@mui/material";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {MaterialReactTable} from "material-react-table";
import {Delete, Download, Edit} from "@mui/icons-material";
import {createRegion, deleteRegion, getAllRegions, updateRegion} from "../../api/regions";
import {mkConfig, generateCsv, download} from 'export-to-csv';
import {regionsQueryKey} from "../../constants";


const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
});


const Regions = () => {
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState({description: "", id: ""});
    const queryClient = useQueryClient();

    const handleExport = (data) => {
        const csv = generateCsv(csvConfig)(data);
        download(csvConfig)(csv);
    }

    const openDeleteConfirmModal = (item) => {
        setItemToDelete(item.original);
        setDeleteDialogOpen(true);
    }

    const {
        isLoading: regionsLoading,
        data: regions = []
    } = useQuery(regionsQueryKey, getAllRegions, {refetchOnWindowFocus: false});

    const {
        mutate: mutateCreateRegion,
        isLoading: isCreateRegionLoading
    } = useMutation({
        mutationFn: createRegion,
        onSettled: () => queryClient.invalidateQueries(regionsQueryKey)
    })

    const {
        mutate: mutateDeleteRegion,
        isLoading: isDeleteRegionLoading
    } = useMutation({
        mutationFn: deleteRegion,
        onSettled: () => queryClient.invalidateQueries(regionsQueryKey)
    });

    const {
        mutate: mutateUpdateRegion,
        isLoading: isUpdateRegionLoading
    } = useMutation({
        mutationFn: updateRegion,
        onSettled: () => queryClient.invalidateQueries(regionsQueryKey)
    });

    const onCreateRegion = async ({values, table}) => {
        await mutateCreateRegion(values);
        table.setCreatingRow(false);
    }

    const onDeleteRegion = async (claimId) => {
        await mutateDeleteRegion(claimId);
        setDeleteDialogOpen(false);
    }

    const onUpdateRegion = async ({values, table}) => {
        await mutateUpdateRegion(values);
        table.setEditingRow(false);
    }


    const columns = useMemo(() => [
        {
            accessorKey: 'id',
            header: 'ID',
            type: 'number',
            enableEditing: false
        },
        {
            accessorKey: 'parent',
            header: 'Parent',
            type: 'string',
            enableEditing: true,

        },
        {
            accessorKey: 'name',
            header: 'Name',
            type: 'string',
            enableEditing: true,

        },
        {
            accessorKey: 'description',
            header: 'Description',
            type: 'string',
            enableEditing: true,
        }
    ], []);


    return (
        <Fragment>
            <Dialog open={deleteDialogOpen}>
                <DialogTitle>Delete Item</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete item "{itemToDelete.name}"?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => onDeleteRegion(itemToDelete.id)}>Yes</Button>
                    <Button variant="outlined" onClick={() => setDeleteDialogOpen(false)}>No</Button>
                </DialogActions>
            </Dialog>
            <Typography variant="h4" sx={{mb: 5}}>Regions</Typography>
            <MaterialReactTable
                columns={columns}
                data={regions}
                enableEditing={true}
                state={{
                    isLoading: regionsLoading,
                    isSaving: isCreateRegionLoading || isDeleteRegionLoading || isUpdateRegionLoading
                }}
                editDisplayMode={"row"} createDisplayMode={"row"}
                enablePagination={false}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableColumnFilterModes={true}
                initialState={{
                    showColumnFilters: true,
                }}
                renderRowActions={({row, table}) => {
                    return (
                        <Box sx={{display: 'flex', gap: '1rem'}}>
                            <Tooltip title="Edit">
                                <IconButton onClick={() => table.setEditingRow(row)}>
                                    <Edit/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                                    <Delete/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    );
                }}
                renderTopToolbarCustomActions={({table}) => {
                    return (
                        <Box display={"flex"} justifyContent={"flex-start"}>
                            <Button
                                variant="contained"
                                onClick={_ => table.setCreatingRow(true)}
                            >
                                Add Region
                            </Button>
                            <Button onClick={_ => handleExport(regions)} variant={"contained"} sx={{ml: 2}}>
                                <Download/>
                                Download CSV
                            </Button>
                        </Box>

                    );

                }}
                onCreatingRowSave={onCreateRegion}
                onEditingRowSave={onUpdateRegion}
            />
        </Fragment>
    );
}

export default Regions;