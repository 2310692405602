import {Fragment, useContext, useEffect, useState} from "react";
import {
    Box,
    Button, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl,
    IconButton, InputLabel, Link, MenuItem, Select, TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getAllClaims} from "../../api/claims";
import {getAllRegions} from "../../api/regions";
import {getAllIndustries} from "../../api/industries";
import {casesQueryKey} from "../../constants";
import {MaterialReactTable} from "material-react-table";
import {Delete, Download, Edit} from "@mui/icons-material";
import ChipMultiSelect from "../custom/ChipMultiSelect";
import {createCase, deleteCase, getAllCases, updateCase} from "../../api/cases";
import {download, generateCsv, mkConfig} from "export-to-csv";
import {DatePicker} from "@mui/x-date-pickers";
import LoginContext from "../../login-context/LoginContext";

const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
});

const Cases = () => {
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [editNewDialogOpen, setEditNewDialogOpen] = useState(false);
    const [itemToEdit, setItemToEdit] = useState({name: "", id: ""});
    const [itemToDelete, setItemToDelete] = useState({name: "", id: ""});
    const [isNewItem, setIsNewItem] = useState(false);
    const [claims, setClaims] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [tokenResponse, setTokenResponse] = useContext(LoginContext)
    const queryClient = useQueryClient();

    useEffect(() => {
            getAllClaims(tokenResponse?.access_token, () => setTokenResponse({}))().then(data => setClaims(data));
            getAllRegions().then(data => setRegions(data.map(i => {
                return {id: i.id, name: i.description}
            })));
            getAllIndustries().then(data => setIndustries(data));
        }, [setTokenResponse, tokenResponse?.access_token]);

    const openDeleteConfirmModal = (item) => {
        setItemToDelete(item.original);
        setDeleteDialogOpen(true);
    }

    const handleExport = (data) => {
        const csv = generateCsv(csvConfig)(data);
        download(csvConfig)(csv);
    }

    const onDeleteCase = (id) => {
        mutateDeleteCase(id);
        setDeleteDialogOpen(false);
    }

    const onCreateCertificationDialogOpen = () => {
        setIsNewItem(true);
        setItemToEdit({});
        setEditNewDialogOpen(true);
    }

    const onEditCertificationDialogOpen = (item) => {
        setIsNewItem(false);
        setItemToEdit(item.original);
        setEditNewDialogOpen(true);
    }

    const onSubmitButtonClick = async () => {
        if (isNewItem) {
            await mutateCreateCase(itemToEdit);
        } else {
            await mutateUpdateCase(itemToEdit);
        }
        setEditNewDialogOpen(false);
    }

    const {
        isLoading: isLegalCaseLoading,
        data: certifications = []
    } = useQuery(casesQueryKey, getAllCases, {refetchOnWindowFocus: false});

    const {
        mutate: mutateCreateCase,
        isLoading: isCreateCaseLoading
    } = useMutation({
        mutationFn: createCase,
        onSettled: () => queryClient.invalidateQueries(casesQueryKey)
    })

    const {
        mutate: mutateDeleteCase,
        isLoading: isDeleteCaseLoading
    } = useMutation({
        mutationFn: deleteCase,
        onSettled: () => queryClient.invalidateQueries(casesQueryKey)
    });

    const {
        mutate: mutateUpdateCase,
        isLoading: isUpdateCaseLoading
    } = useMutation({
        mutationFn: updateCase,
        onSettled: () => queryClient.invalidateQueries(casesQueryKey)
    });

    const columns = [
        {
            accessorKey: 'id',
            header: 'ID',
            type: 'number',
            enableEditing: false,
            size: 50
        },
        {
            accessorKey: 'link',
            header: 'Link to Lawsuit or Findings',
            type: 'string',
            Cell: ({renderedCellValue, row}) => (
                <Link href={row.original.link} target="_blank">{row.original.link}</Link>
            )
        },
        {
            accessorKey: 'name',
            header: 'Complaint Name',
            type: 'string',
            enableEditing: true,
            size: 350
        },
        {
            accessorFn: original => original.claims?.map(c => claims.find(claim => claim.id === c)?.name).join(", "),
            header: 'Green Claims',
            size: 400,
            Cell: ({renderedCellValue, row}) =>
                (
                    <Box>
                        {row.original.claims?.map((claim, index) => (
                            <Chip key={index} label={(claims || []).find(c => c.id === claim)?.name}
                                  color="primary" sx={{m: 0.5}}/>
                        ))}
                    </Box>
                )
            ,

        },
        {
            accessorFn: original => original.industries?.map(i => industries.find(ind => ind.id === i)?.name).join(", "),
            header: 'Product Category',
            size: 400,
            Cell: ({renderedCellValue, row}) =>
                (
                    <Box>
                        {row.original.industries?.map((industry, index) => (
                            <Chip key={index} label={(industries || []).find(i => i.id === industry)?.name}
                                  color="primary" sx={{m: 0.5}}/>
                        ))}
                    </Box>
                )
        },
        {
            accessorFn: original => original.regions?.map(r => regions.find(reg => reg.id === r)?.name).join(", "),
            header: 'Regions',
            type: 'string',
            size: 400,
            Cell: ({renderedCellValue, row}) =>
                (
                    <Box>
                        {row.original.regions?.map((region, index) => (
                            <Chip key={index} label={(regions || []).find(i => i.id === region)?.name}
                                  color="primary" sx={{m: 0.5}}/>
                        ))}
                    </Box>
                )
        },
        {
            accessorKey: 'type',
            header: 'Source/Type of Complaint',
            enableEditing: true,
        },
        {
            accessorKey: 'year',
            header: 'Year',
            enableEditing: true,
        },
        {
            accessorKey: 'product',
            header: 'Product',
            enableEditing: true,
        },
        {
            accessorKey: 'status',
            header: 'Status of Suit',
            enableEditing: true,
        },
        {
            accessorKey: 'settlementAmount',
            header: 'Settlement $ amount',
            enableEditing: true,
        },
        {
            accessorKey: 'settlementLink',
            header: 'Settlement Link',
            enableEditing: true,
        },
        {
            accessorKey: 'notes',
            header: 'Notes',
            enableEditing: true,
        },

    ];


    return (
        <Fragment>
            <Dialog open={deleteDialogOpen}>
                <DialogTitle>Delete Item</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete item "{itemToDelete.name}"?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => onDeleteCase(itemToDelete.id)}>Yes</Button>
                    <Button variant="outlined" onClick={() => setDeleteDialogOpen(false)}>No</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={editNewDialogOpen} fullWidth maxWidth={"md"}>
                <DialogTitle>
                    {isNewItem ? "Add New Legal Case" : "Edit Legal Case"}
                </DialogTitle>
                <DialogContent>
                    <Box display={"flex"} flexDirection={"column"}>
                        <TextField label={"Complaint Name"} sx={{mt: 2}}
                                   onChange={e => setItemToEdit({...itemToEdit, name: e.target.value})}
                                   value={itemToEdit.name}/>
                        <TextField label={"Link to Lawsuit or Findings"} sx={{mt: 2}} value={itemToEdit.link}
                                   onChange={e => setItemToEdit({...itemToEdit, link: e.target.value})}/>
                        <ChipMultiSelect options={claims} selectedOptions={itemToEdit.claims || []}
                                         label={"Green Claims"} sx={{mt: 2}}
                                         setSelectedOptions={v => setItemToEdit({...itemToEdit, claims: v})}/>
                        <ChipMultiSelect options={industries} selectedOptions={itemToEdit.industries || []}
                                         label={"Product Categories"} sx={{mt: 2}}
                                         setSelectedOptions={v => setItemToEdit({...itemToEdit, industries: v})}/>
                        <ChipMultiSelect options={regions}
                                         selectedOptions={itemToEdit.regions || []}
                                         label={"Regions"} sx={{mt: 2}}
                                         setSelectedOptions={v => setItemToEdit({...itemToEdit, regions: v})}/>
                        <TextField label={"Source/Type of Complaint"} sx={{mt: 2}}
                                   onChange={e => setItemToEdit({...itemToEdit, type: e.target.value})}
                                   value={itemToEdit.type}/>
                        <DatePicker views={["year"]} label={"Year"} sx={{mt: 2}} value={new Date(itemToEdit.year || 2024,1,1)} onChange={v => setItemToEdit({...itemToEdit, year: (new Date(v)).getFullYear()})}
                        />
                        <TextField label={"Product"} sx={{mt: 2}}
                                       onChange={e => setItemToEdit({...itemToEdit, product: e.target.value})}
                                       value={itemToEdit.product}/>
                            <TextField label={"Settlement $ amount"} sx={{mt: 2}}
                                       onChange={e => setItemToEdit({...itemToEdit, settlementAmount: e.target.value})}
                                       value={itemToEdit.settlementAmount}/>
                            <TextField label={"Settlement Link"} sx={{mt: 2}}
                                       onChange={e => setItemToEdit({...itemToEdit, settlementLink: e.target.value})}
                                       value={itemToEdit.settlementLink}/>
                        <FormControl fullWidth sx={{mt: 2}}>
                            <InputLabel id="demo-simple-select-label">Case Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={itemToEdit.status || null}
                                label="Status"
                                onChange={(e) => setItemToEdit({...itemToEdit, status: e.target.value})}
                            >
                                <MenuItem value={null}>N/A</MenuItem>
                                <MenuItem value={'Pending'}>Pending</MenuItem>
                                <MenuItem value={'Settlement'}>Settlement</MenuItem>
                                <MenuItem value={'Recommendation Made'}>Recommendation Made</MenuItem>
                                <MenuItem value={'Dismissed'}>Dismissed</MenuItem>
                                <MenuItem value={'Settled'}>Settled</MenuItem>
                                <MenuItem value={'Open'}>Open</MenuItem>
                            </Select>
                        </FormControl>

                            <TextField label={"Notes"} sx={{mt: 2}}
                                       onChange={e => setItemToEdit({...itemToEdit, notes: e.target.value})}
                                       value={itemToEdit.notes}/>

                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setEditNewDialogOpen(false)}>Cancel</Button>
                    <Button variant="contained" type={"submit"} onClick={onSubmitButtonClick}>Save</Button>
                </DialogActions>
            </Dialog>
            <MaterialReactTable
                columns={columns}
                data={certifications}
                enableEditing={true}
                state={{
                    isLoading: isLegalCaseLoading,
                    isSaving: isCreateCaseLoading || isDeleteCaseLoading || isUpdateCaseLoading
                }}
                editDisplayMode={"row"} createDisplayMode={"row"}
                enablePagination={true}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableColumnFilterModes={true}
                enableColumnPinning={true}
                enableColumnResizing={true}
                initialState={{
                    showColumnFilters: false,
                    columnPinning: {left: ['mrt-row-actions', 'name']}
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        size: 180, //if using layoutMode that is not 'semantic', the columns will not auto-size, so you need to set the size manually
                        grow: false,
                    },
                }}
                renderRowActions={({row, table}) => {
                    return (
                        <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                            <Tooltip title="Edit">
                                <IconButton onClick={() => onEditCertificationDialogOpen(row)}>
                                    <Edit/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                                    <Delete/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    );
                }}
                renderTopToolbarCustomActions={({table}) => {
                    return (
                        <Box display={"flex"} justifyContent={"flex-start"}>
                            <Button
                                variant="contained"
                                onClick={onCreateCertificationDialogOpen}
                            >
                                Add Legal Case
                            </Button>
                            <Button variant={"contained"} sx={{ml: 2}} onClick={_ => handleExport(certifications)}>
                                <Download/>
                                Download CSV
                            </Button>
                        </Box>
                    );

                }}

            />

        </Fragment>
    )
}

export default Cases;