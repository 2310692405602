import {baseUrl} from "./baseApi";

export const getAllIndustries = () => fetch(baseUrl + "/api/Industries")
    .then(response => response.json());

export const createIndustry = (industry) => fetch(baseUrl + "/api/Industries", {
    method: "POST",
    headers: {
        "Content-Type": "application/json"
    },
    body: JSON.stringify(industry)
}).then(response => response.json());

export const deleteIndustry = (industryId) => fetch(baseUrl + `/api/Industries/${industryId}`, {
    method: "DELETE"
});

export const updateIndustry = (industry) => fetch(baseUrl + `/api/Industries/${industry.id}`, {
    method: "PUT",
    headers: {
        "Content-Type": "application/json"
    },
    body: JSON.stringify(industry)
}).then(response => response.json());