import {baseUrl} from "./baseApi";

export const getAllCertifications = () => fetch(baseUrl + "/api/Certifications/list")
    .then(response => response.json());

export const createCertification = (certification) => fetch(baseUrl + "/api/Certifications", {
    method: "POST",
    headers: {
        "Content-Type": "application/json"
    },
    body: JSON.stringify(certification)
}).then(response => response.json());

export const deleteCertification = (certificationId) => fetch(baseUrl + `/api/Certifications/${certificationId}`, {
    method: "DELETE"
});

export const updateCertification = (certification) => fetch(baseUrl + `/api/Certifications/${certification.id}`, {
    method: "PUT",
    headers: {
        "Content-Type": "application/json"
    },
    body: JSON.stringify(certification)
}).then(response => response.json());
