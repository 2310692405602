import {Avatar, Box, Button, CssBaseline, Grid, Paper, Typography} from "@mui/material";
import {LockOutlined} from "@mui/icons-material";
import {useGoogleLogin} from "@react-oauth/google";
import {styled} from "styled-components";
import {useTheme} from "@mui/system";
import {useContext} from "react";
import LoginContext from "../../login-context/LoginContext";


const SignInDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const SignIn = () => {
    const theme = useTheme();
    const [tokenResponse, setTokenResponse] = useContext(LoginContext)
    const login = useGoogleLogin({
        onSuccess: r => {setTokenResponse(r || tokenResponse)},
    });

    return (
        <Box sx={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor:
                theme.palette.type === "light"
                    ? theme.palette.grey
                    : theme.palette.grey,
        }}>
            <CssBaseline/>
            {/* <Grid item xs={false} sm={4} md={7} className={classes.image} /> */}
            <Grid
                item
                xs={12}
                sm={8}
                md={5}
                component={Paper}
                elevation={1}
                square
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "400px"
                }}

            >

                <SignInDiv sx={{m: theme.spacing(2, 6)}}>
                    <Avatar sx={{
                        margin: theme.spacing(2),
                        backgroundColor: "rgba(23, 219, 78, 1)"
                    }}>
                        <LockOutlined/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box sx={{m: 3}}>
                        <Button variant={"contained"} onClick={() => login()}>Login</Button>
                        {/*<GoogleLogin onSuccess={r => {setTokenResponse(r);console.log(r)}} onError={r => console.log(r)}/>*/}
                    </Box>
                </SignInDiv>
            </Grid>
        </Box>
    )
}

export default SignIn;