import {Fragment, useContext, useMemo, useState} from "react";
import {MaterialReactTable} from "material-react-table";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
    Typography
} from "@mui/material";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {createClaim, deleteClaim, getAllClaims, updateClaim} from "../../api/claims";
import {Delete, Download, Edit} from "@mui/icons-material";
import {mkConfig, generateCsv, download} from 'export-to-csv';
import {claimQueryKey} from "../../constants";
import LoginContext from "../../login-context/LoginContext";


const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
});

const Claims = () => {
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState({name: "", id: ""});
    const [tokenResponse, setTokenResponse] = useContext(LoginContext)
    const queryClient = useQueryClient();

    const handleExport = (data) => {
        const csv = generateCsv(csvConfig)(data);
        download(csvConfig)(csv);
    }

    const openDeleteConfirmModal = (item) => {
        setItemToDelete(item.original);
        setDeleteDialogOpen(true);
    }

    const {
        isLoading: claimsLoading,
        data: claims = []
    } = useQuery(claimQueryKey, getAllClaims(tokenResponse?.access_token, () => setTokenResponse({})), {refetchOnWindowFocus: false});

    const {
        mutate: mutateCreateClaim,
        isLoading: isCreateClaimLoading
    } = useMutation({
        mutationFn: createClaim(tokenResponse?.access_token, () => setTokenResponse({})),
        onSettled: () => queryClient.invalidateQueries(claimQueryKey)
    })

    const {
        mutate: mutateDeleteClaim,
        isLoading: isDeleteClaimLoading
    } = useMutation({
        mutationFn: deleteClaim(tokenResponse?.access_token, () => setTokenResponse({}) ),
        onSettled: () => queryClient.invalidateQueries(claimQueryKey)
    });

    const {
        mutate: mutateUpdateClaim,
        isLoading: isUpdateClaimLoading
    } = useMutation({
        mutationFn: updateClaim(tokenResponse?.access_token, () => setTokenResponse({})),
        onSettled: () => queryClient.invalidateQueries(claimQueryKey)
    });


    const onCreateClaim = async ({values, table}) => {
        await mutateCreateClaim(values);
        table.setCreatingRow(false);
    }

    const onDeleteClaim = async (claimId) => {
        await mutateDeleteClaim(claimId);
        setDeleteDialogOpen(false);
    }

    const onUpdateClaim = async ({values, table}) => {
        await mutateUpdateClaim(values);
        table.setEditingRow(false);
    }

    const columns = useMemo(() => [
        {
            accessorKey: 'id',
            header: 'ID',
            type: 'number',
            enableEditing: false
        },
        {
            accessorKey: 'name',
            header: 'Name',
            type: 'string',
            enableEditing: true,

        }
    ], []);

    return (
        <Fragment>
            <Dialog open={deleteDialogOpen}>
                <DialogTitle>Delete Item</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete item "{itemToDelete.name}"?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => onDeleteClaim(itemToDelete.id)}>Yes</Button>
                    <Button variant="outlined" onClick={() => setDeleteDialogOpen(false)}>No</Button>
                </DialogActions>
            </Dialog>
            <Typography variant="h4" sx={{mb: 5}}>Claims</Typography>
            <MaterialReactTable
                columns={columns}
                data={claims}
                enableEditing={true}
                state={{
                    isLoading: claimsLoading,
                    isSaving: isCreateClaimLoading || isDeleteClaimLoading || isUpdateClaimLoading
                }}
                editDisplayMode={"row"} createDisplayMode={"row"}
                enablePagination={false}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableColumnFilterModes={true}
                initialState={{
                    showColumnFilters: true,
                }}
                renderRowActions={({row, table}) => {
                    return (
                        <Box sx={{display: 'flex', gap: '1rem'}}>
                            <Tooltip title="Edit">
                                <IconButton onClick={() => table.setEditingRow(row)}>
                                    <Edit/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                                    <Delete/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    );
                }}
                renderTopToolbarCustomActions={({table}) => {
                    return (
                        <Box display={"flex"} justifyContent={"flex-start"}>
                            <Button
                                variant="contained"
                                onClick={_ => table.setCreatingRow(true)}
                            >
                                Add Claim
                            </Button>
                            <Button onClick={_ => handleExport(claims)} variant={"contained"} sx={{ml: 2}}>
                                <Download/>
                                Download CSV
                            </Button>
                        </Box>
                    );

                }}
                onCreatingRowSave={onCreateClaim}
                onEditingRowSave={onUpdateClaim}
            />
        </Fragment>
    );
}


export default Claims;