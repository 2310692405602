import {authorizedFetch, baseUrl} from "./baseApi";

export const getAllRegulations = (token, logout) => () => authorizedFetch(baseUrl + "/api/Regulations/list", token, logout)
    .then(response => response.json());

export const createRegulation = (file, token, logout) => (regulation) => {
    const formData = new FormData();
    formData.append("value", JSON.stringify(regulation));
    if(file) {
        formData.append("file", file);
    }

    return authorizedFetch(baseUrl + "/api/Regulations", token, logout, {
        method: "POST",
        body: formData
    }).then(response => response.json());
}

export const deleteRegulation = (token, logout) => (regulationId) => authorizedFetch(baseUrl + `/api/Regulations/${regulationId}`, token, logout, {
    method: "DELETE"
});

export const updateRegulation = (file, token, logout) => (regulation) => {
    const formData = new FormData();
    formData.append("value", JSON.stringify(regulation));
    if(file) {
        formData.append("file", file);
    }

    return authorizedFetch(baseUrl + `/api/Regulations/${regulation.id}`, token, logout, {
        method: "PUT",
        body: formData
    }).then(response => response.json());
}