import {baseUrl} from "./baseApi";

export const getAllCases = (token, logout) => fetch(baseUrl + "/api/LegalCases")
    .then(response => response.json());

export const createCase = (legalCase, token, logout) => fetch(baseUrl + "/api/LegalCases", {
    method: "POST",
    headers: {
        "Content-Type": "application/json"
    },
    body: JSON.stringify(legalCase, )
}).then(response => response.json());

export const deleteCase = (legalCaseId, token, logout) => fetch(baseUrl + `/api/LegalCases/${legalCaseId}`, {
    method: "DELETE"
});

export const updateCase = (legalCase, token, logout) => fetch(baseUrl + `/api/LegalCases/${legalCase.id}`, {
    method: "PUT",
    headers: {
        "Content-Type": "application/json"
    },
    body: JSON.stringify(legalCase)
}).then(response => response.json());