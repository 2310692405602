import './App.css';
import {styled} from "styled-components";
import {Container, createTheme, Divider, Tab, Tabs, ThemeProvider} from "@mui/material";
import {useState} from "react";
import Claims from "./components/claims/Claims";
import {QueryClient, QueryClientProvider} from "react-query";
import Industries from "./components/industries/Industries";
import Regions from "./components/regions/Regions";
import Certifications from "./components/certifications/Certifications";
import Cases from "./components/cases/Cases";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import LoginContext from "./login-context/LoginContext";
import SignInContainer from "./components/sign-in/SignInContainer";
import Regulations from "./components/regulations/Regulations";
import SpecialConsiderations from "./components/Special Consideratons/SpecialConsiderations";


const MainContainer = styled.div`
    display: flex;
    width: 100%;
    height: calc(100vh - 64px);
    margin-top: 64px;
`;

const tabs = [
    {label: "Claims", component: <Claims/>},
    {label: "Product Categories", component: <Industries/>},
    {label: "Regions", component: <Regions/>},
    {label: "Certifications", component: <Certifications/>},
    {label: "Regulations", component: <Regulations/>},
    {label: "Law Cases", component: <Cases/>},
    {label: "Special Considerations", component: <SpecialConsiderations/>}
]

const theme = createTheme({
    palette: {
        primary: {
            main: "rgb(27, 87, 43)"
        }
    }
});

const client = new QueryClient();

function App() {
    const [selectedTab, setSelectedTab] = useState(0);
    const [tokenResponse, setTokenResponse] = useState({});

    return (
        <ThemeProvider theme={theme}>
            <QueryClientProvider client={client}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <LoginContext.Provider value={[tokenResponse, setTokenResponse]}>
                        <SignInContainer>
                            <MainContainer>
                                <Container maxWidth="xl">
                                    <Tabs value={selectedTab} onChange={(e, v) => setSelectedTab(v)}>
                                        {tabs.map((tab, index) => (
                                            <Tab key={index} label={tab.label}/>
                                        ))}
                                    </Tabs>
                                    <Divider sx={{mb: 10}}/>
                                    {tabs[selectedTab].component}
                                </Container>
                            </MainContainer>
                        </SignInContainer>
                    </LoginContext.Provider>
                </LocalizationProvider>
            </QueryClientProvider>
        </ThemeProvider>
    );
}

export default App;
