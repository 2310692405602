import {Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select} from "@mui/material";
import {useTheme} from "@mui/system";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const getStyles = (id, values, theme) => {
    return {
        fontWeight:
            values.indexOf(id) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const ChipMultiSelect = ({options, selectedOptions, setSelectedOptions, label, name, ...props}) => {
    const theme = useTheme();

    const handleChange = (event) => {
        const {
            target: {value},
        } = event;
        setSelectedOptions(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <FormControl sx={{m: 1, width: 300}} {...props}>
            <InputLabel id={name+'label'}>{label}</InputLabel>
            <Select
                labelId={name+'label'}
                id={name}
                multiple
                value={selectedOptions}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label={label}/>}
                renderValue={(selected) => (
                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                        {selected.map((value) => (
                            <Chip key={value} label={options.find(o => o.id === value).name} color="primary"/>
                        ))}
                    </Box>
                )}
                MenuProps={MenuProps}
                label={label}
            >
                {options.map((option, index) => (
                    <MenuItem
                        key={index}
                        value={option.id}
                        style={getStyles(option.id, selectedOptions, theme)}
                    >
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default ChipMultiSelect;