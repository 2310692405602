import {useContext} from "react";
import {hasGrantedAllScopesGoogle} from "@react-oauth/google";
import SignIn from "./SignIn";
import LoginContext from "../../login-context/LoginContext";


const SignInContainer = ({children}) => {
    const [tokenResponse] = useContext(LoginContext);

    const granted = hasGrantedAllScopesGoogle(tokenResponse, "email");

    if(!granted) {
        return (
            <SignIn/>
        )
    }

    return children;
}

export default SignInContainer;