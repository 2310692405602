import {Fragment, useContext, useEffect, useState} from "react";
import {
    Box,
    Button, Checkbox, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, Link, MenuItem, Select,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import ChipMultiSelect from "../custom/ChipMultiSelect";
import {DatePicker} from "@mui/x-date-pickers";
import {MaterialReactTable} from "material-react-table";
import {Delete, Download, Edit} from "@mui/icons-material";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getAllClaims} from "../../api/claims";
import {getAllRegions} from "../../api/regions";
import {getAllIndustries} from "../../api/industries";
import LoginContext from "../../login-context/LoginContext";
import {download, generateCsv, mkConfig} from "export-to-csv";
import {regulationQueryKey} from "../../constants";
import {createRegulation, deleteRegulation, getAllRegulations, updateRegulation} from "../../api/regulations";
import {MuiFileInput} from "mui-file-input";

const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
});

const Regulations = () => {
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [editNewDialogOpen, setEditNewDialogOpen] = useState(false);
    const [itemToEdit, setItemToEdit] = useState({name: "", id: ""});
    const [itemToDelete, setItemToDelete] = useState({name: "", id: ""});
    const [isNewItem, setIsNewItem] = useState(false);
    const [claims, setClaims] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [tokenResponse, setTokenResponse] = useContext(LoginContext)
    const [file, setFile] = useState(null);
    const queryClient = useQueryClient();


    useEffect(() => {
        getAllClaims(tokenResponse?.access_token, () => setTokenResponse({}))().then(data => setClaims(data));
        getAllRegions().then(data => setRegions(data.map(i => {
            return {id: i.id, name: i.description}
        })));
        getAllIndustries().then(data => setIndustries(data));
    }, [setTokenResponse, tokenResponse?.access_token]);

    const openDeleteConfirmModal = (item) => {
        setItemToDelete(item.original);
        setDeleteDialogOpen(true);
    }

    const handleExport = (data) => {
        const csv = generateCsv(csvConfig)(data);
        download(csvConfig)(csv);
    }

    const onCreateRegulationDialogOpen = () => {
        setItemToEdit({enabledForClaimsPicker: true, enabledForChatESG: true});
        setFile(null);
        setIsNewItem(true);
        setEditNewDialogOpen(true);
    }

    const onEditRegulationDialogOpen = (item) => {
        setItemToEdit(item.original);
        setFile(null);
        setIsNewItem(false);
        setEditNewDialogOpen(true);
    }

    const onSubmitButtonClick = () => {
        if (isNewItem) {
            mutateCreateRegulation(itemToEdit);
        } else {
            mutateUpdateRegulation(itemToEdit);
        }
        setFile(null);
        setEditNewDialogOpen(false);
    }

    const onDeleteRegulation = (id) => {
        mutateDeleteRegulation(id);
        setDeleteDialogOpen(false);
    }

    const {
        isLoading: isRegulationsLoading,
        data: regulations = []
    } = useQuery(regulationQueryKey, getAllRegulations(tokenResponse?.access_token, () => setTokenResponse({})), {refetchOnWindowFocus: false});

    const {
        mutate: mutateCreateRegulation,
        isLoading: isCreateRegulationLoading
    } = useMutation({
        mutationFn: createRegulation(file, tokenResponse?.access_token, () => setTokenResponse({})),
        onSettled: () => queryClient.invalidateQueries(regulationQueryKey)
    });

    const {
        mutate: mutateDeleteRegulation,
        isLoading: isDeleteRegulationLoading
    } = useMutation({
        mutationFn: deleteRegulation(tokenResponse?.access_token, () => setTokenResponse({})),
        onSettled: () => queryClient.invalidateQueries(regulationQueryKey)
    });

    const {
        mutate: mutateUpdateRegulation,
        isLoading: isUpdateRegulationLoading
    } = useMutation({
        mutationFn: updateRegulation(file, tokenResponse?.access_token, () => setTokenResponse({})),
        onSettled: () => queryClient.invalidateQueries(regulationQueryKey)
    });

    const columns = [
        {
            accessorKey: 'id',
            header: 'ID',
            type: 'number',
            enableEditing: false,
            size: 50
        },
        {
            accessorKey: 'name',
            header: 'Regulation Name',
            type: 'string',
            enableEditing: true,
            size: 350
        },
        {
            accessorKey: 'altName',
            header: 'Alternate Regulation Name',
            type: 'string',
            enableEditing: true,
            size: 350
        },
        {
            accessorFn: original => original.claimIds?.map(c => claims.find(claim => claim.id === c)?.name).join(", "),
            header: 'Green Claims',
            size: 400,
            Cell: ({renderedCellValue, row}) =>
                (
                    <Box>
                        {row.original.claimIds?.map((claim, index) => (
                            <Chip key={index} label={(claims || []).find(c => c.id === claim)?.name}
                                  color="primary" sx={{m: 0.5}}/>
                        ))}
                    </Box>
                )
            ,
        },
        {
            accessorFn: original => original.industryIds?.map(i => industries.find(ind => ind.id === i)?.name).join(", "),
            header: 'Product Category',
            size: 400,
            Cell: ({renderedCellValue, row}) =>
                (
                    <Box>
                        {row.original.industryIds?.map((industry, index) => (
                            <Chip key={index} label={(industries || []).find(i => i.id === industry)?.name}
                                  color="primary" sx={{m: 0.5}}/>
                        ))}
                    </Box>
                )
        },
        {
            accessorFn: original => regions.find(reg => reg.id === original.regionId)?.name,
            header: 'Regions',
            type: 'string',
            size: 400,
        },
        {
            accessorKey: 'website',
            header: 'Website URL (where found)',
            type: 'string',
            Cell: ({renderedCellValue, row}) => (
                <Link href={row.original.website} target="_blank">{row.original.website}</Link>
            )
        },
        {
            accessorFn: original => original.effective ? new Date(original.effective).toLocaleDateString() : null,
            header: 'Effective Date',
            enableEditing: true,
        },
        {
            accessorKey: 'file',
            header: 'File',
            Cell: ({renderedCellValue, row}) => (
                <Link href={row.original.file} target="_blank">{row.original.file}</Link>
            )
        },
        {
            accessorKey: 'enabledForChatESG',
            header: "ChatESG",
            Cell: ({renderedCellValue, row}) => (
                <Checkbox checked={row.original.enabledForChatESG} disabled/>
            )

        },
        {
            accessorKey: 'enabledForClaimsPicker',
            header: "Claims Picker",
            Cell: ({renderedCellValue, row}) => (
                <Checkbox checked={row.original.enabledForClaimsPicker} disabled/>
            )
        },
        {
            accessorKey: 'status',
            header: 'Status',
            enableEditing: true,
        },
        {
            accessorKey: 'type',
            header: 'Type',
            enableEditing: true,
        }
    ];

    return (
        <Fragment>
            <Dialog open={deleteDialogOpen}>
                <DialogTitle>Delete Item</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete item "{itemToDelete.name}"?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => onDeleteRegulation(itemToDelete.id)}>Yes</Button>
                    <Button variant="outlined" onClick={() => setDeleteDialogOpen(false)}>No</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={editNewDialogOpen} fullWidth maxWidth={"md"}>
                <DialogTitle>
                    {isNewItem ? "Add New Regulation" : "Edit Regulation"}
                </DialogTitle>
                <DialogContent>
                    <Box display={"flex"} flexDirection={"column"}>
                        <TextField label={"Regulation Name"} sx={{mt: 2}}
                                   onChange={e => setItemToEdit({...itemToEdit, name: e.target.value})}
                                   value={itemToEdit.name}/>
                        <TextField label={"Alternate Regulation Name"} sx={{mt: 2}} value={itemToEdit.altName}
                                   onChange={e => setItemToEdit({...itemToEdit, altName: e.target.value})}/>
                        <ChipMultiSelect options={claims} selectedOptions={itemToEdit.claimIds || []}
                                         label={"Green Claims"} sx={{mt: 2}}
                                         setSelectedOptions={v => setItemToEdit({...itemToEdit, claimIds: v})}/>
                        <ChipMultiSelect options={industries} selectedOptions={itemToEdit.industryIds || []}
                                         label={"Product Categories"} sx={{mt: 2}}
                                         setSelectedOptions={v => setItemToEdit({...itemToEdit, industryIds: v})}/>
                        <FormControl fullWidth sx={{mt: 2}}>
                            <InputLabel id="region-select-label">Region</InputLabel>
                            <Select
                                         value={itemToEdit.regionId || null}
                                         labelId={"region-select-label"}
                                         label={"Region"}
                                         onChange={e => setItemToEdit({...itemToEdit, regionId: e.target.value})}>
                                {regions.map((region, index) => (
                                    <MenuItem key={index} value={region.id}>{region.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <MuiFileInput value={file} onChange={setFile} sx={{mt: 2}} label={"Regulation File"}/>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox
                                checked={itemToEdit.enabledForChatESG}
                                onChange={(e, c) => setItemToEdit({...itemToEdit, enabledForChatESG: c})}/>}
                                              label={"Is For ChatESG?"} sx={{mt: 2}}/>
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox
                                checked={itemToEdit.enabledForClaimsPicker}
                                onChange={(e, c) => setItemToEdit({...itemToEdit, enabledForClaimsPicker: c})}/>}
                                              label={"Is For Claims Picker?"} sx={{mt: 2}}/>
                        </FormGroup>

                        <TextField label={"Website URL (where found)"} sx={{mt: 2}}
                                   onChange={e => setItemToEdit({...itemToEdit, website: e.target.value})}
                                   value={itemToEdit.website}/>
                        <DatePicker label={"Effective Date"} sx={{mt: 2}} value={new Date(itemToEdit.effective)}
                                    onChange={v => setItemToEdit({...itemToEdit, effective: v})}
                        />
                        <FormControl fullWidth sx={{mt: 2}}>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={itemToEdit.status || null}
                                label="Status"
                                onChange={(e) => setItemToEdit({...itemToEdit, status: e.target.value})}
                            >
                                <MenuItem value={null} disabled>N/A</MenuItem>
                                <MenuItem value={'Vetoed'}>Vetoed</MenuItem>
                                <MenuItem value={'Passed'}>Passed</MenuItem>
                                <MenuItem value={'Pending'}>Pending</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{mt: 2}}>
                            <InputLabel id="type-select-label">Type</InputLabel>
                            <Select
                                labelId="type-select-label"
                                id="type-simple-select"
                                value={itemToEdit.type || null}
                                label="Type"
                                onChange={(e) => setItemToEdit({...itemToEdit, type: e.target.value})}
                            >
                                <MenuItem value={null}>N/A</MenuItem>
                                <MenuItem value={'Regulation'}>Regulation</MenuItem>
                                <MenuItem value={'Guideline'}>Guideline</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setEditNewDialogOpen(false)}>Cancel</Button>
                    <Button variant="contained" type={"submit"} onClick={onSubmitButtonClick}>Save</Button>
                </DialogActions>
            </Dialog>
            <MaterialReactTable
                columns={columns}
                data={regulations}
                enableEditing={true}
                state={{
                    isLoading: isRegulationsLoading,
                    isSaving: isCreateRegulationLoading || isDeleteRegulationLoading || isUpdateRegulationLoading
                }}
                editDisplayMode={"row"} createDisplayMode={"row"}
                enablePagination={true}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableColumnFilterModes={true}
                enableColumnPinning={true}
                enableColumnResizing={true}
                initialState={{
                    showColumnFilters: false,
                    columnPinning: {left: ['mrt-row-actions', 'name']}
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        size: 180, //if using layoutMode that is not 'semantic', the columns will not auto-size, so you need to set the size manually
                        grow: false,
                    },
                }}
                renderRowActions={({row, table}) => {
                    return (
                        <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
                            <Tooltip title="Edit">
                                <IconButton onClick={() => onEditRegulationDialogOpen(row)}>
                                    <Edit/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                                    <Delete/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    );
                }}
                renderTopToolbarCustomActions={({table}) => {
                    return (
                        <Box display={"flex"} justifyContent={"flex-start"}>
                            <Button
                                variant="contained"
                                onClick={onCreateRegulationDialogOpen}
                            >
                                Add Regulation
                            </Button>
                            <Button variant={"contained"} sx={{ml: 2}} onClick={_ => handleExport(regulations)}>
                                <Download/>
                                Download CSV
                            </Button>
                        </Box>
                    );

                }}

            />

        </Fragment>
    );
}

export default Regulations;