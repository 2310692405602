import {baseUrl} from "./baseApi";

export const getAllRegions = () => fetch(baseUrl + "/api/Regions")
    .then(response => response.json());

export const createRegion = (region) => fetch(baseUrl + "/api/Regions", {
    method: "POST",
    headers: {
        "Content-Type": "application/json"
    },
    body: JSON.stringify(region)
}).then(response => response.json());


export const deleteRegion = (regionId) => fetch(baseUrl + `/api/Regions/${regionId}`, {
    method: "DELETE"
});

export const updateRegion = (region) => fetch(baseUrl + `/api/Regions/${region.id}`, {
    method: "PUT",
    headers: {
        "Content-Type": "application/json"
    },
    body: JSON.stringify(region)
}).then(response => response.json());