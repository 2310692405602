import {authorizedFetch, baseUrl} from "./baseApi";

export const getAllClaims = (token, logout) => () => authorizedFetch(baseUrl + "/api/Claims/list", token, logout)
    .then(response => response.json());

export const createClaim = (token, logout) => (claim) => authorizedFetch(baseUrl + "/api/Claims", token, logout, {
    method: "POST",
    headers: {
        "Content-Type": "application/json"
    },
    body: JSON.stringify(claim)
}).then(response => response.json());

export const deleteClaim = (token, logout) => (claimId) => authorizedFetch(baseUrl + `/api/Claims/${claimId}`, token, logout, {
    method: "DELETE"
});

export const updateClaim = (token, logout) => (claim) => authorizedFetch(baseUrl + `/api/Claims/${claim.id}`, token, logout, {
    method: "PUT",
    headers: {
        "Content-Type": "application/json"
    },
    body: JSON.stringify(claim)
}).then(response => response.json());