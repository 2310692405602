import {baseUrl} from "./baseApi";

export const getAllSpecialConsiderations = () => fetch(baseUrl + "/api/SpecialConsiderations")
    .then(response => response.json());

export const deleteSpecialConsideration = (specialConsiderationId) => fetch(baseUrl + `/api/SpecialConsiderations/${specialConsiderationId}`, {
    method: "DELETE"
});

export const createSpecialConsideration = (specialConsideration) => fetch(baseUrl + "/api/SpecialConsiderations", {
    method: "POST",
    headers: {
        "Content-Type": "application/json"
    },
    body: JSON.stringify(specialConsideration)
}).then(response => response.json());

export const updateSpecialConsideration = (specialConsideration) => fetch(baseUrl + `/api/SpecialConsiderations/${specialConsideration.id}`, {
    method: "PUT",
    headers: {
        "Content-Type": "application/json"
    },
    body: JSON.stringify(specialConsideration)
}).then(response => response.json());